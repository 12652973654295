import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import H2 from 'components/typography/h2'

const Copy = ({ children, header, ...props }) => {
  return (
    <Container {...props}>
      <H2>{header}</H2>
      {children}
    </Container>
  )
}

Copy.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
}

const Container = styled.div`
  margin-top: 60px;

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

export default Copy
