import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import H2 from 'components/new/typography/h2'
import H4 from 'components/new/typography/h4'
import Content from 'components/new/content'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Spacer from 'components/new/spacer'

const AdditionalResources = ({ resources, ...rest }) => {
  return (
    <LightBackground {...rest}>
      <Content kind='full'>
        <H2>Additional Resources</H2>
        <Spacer size='l' />
        <Grid>
          {resources.map(({ title, links }) => (
            <Column key={`AdditionalResources-${title}`}>
              <H4 as='h3'>{title}</H4>
              <Spacer size='s' />
              <ul>
                {links.map((link, i) => (
                  <li key={`AdditionalResources-${title}-${i}`}>{link}</li>
                ))}
              </ul>
            </Column>
          ))}
        </Grid>
      </Content>
    </LightBackground>
  )
}

AdditionalResources.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(PropTypes.node).isRequired,
    })
  ).isRequired,
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Grid = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const Column = styled.div`
  margin-top: 24px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 4px 0;
      padding: 0;
    }
  }

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media (min-width: 800px) {
    ${column('1/3')}
    margin-top: 0;
  }
`

export default AdditionalResources
